<template>
  <div class="col-span-12 lg:col-span-8 2xl:col-span-9">
    <div class="intro-y box lg:mt-5">
      <div
        class="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400"
      >
        <h2 class="mr-auto text-base font-medium">{{ $t("permissiоns/services") }}</h2>
      </div>

      <div class="p-5">
        <div class="flex flex-col xl:flex-row">
          <form class="flex-1 mt-6 xl:mt-0" @submit.stop.prevent="onUpdateUser">
            <FieldsFormDisplay
              :collection="collection!"
              :item="props.profileData"
              :fields="fields"
              v-on="fieldsEventListeners"
            />

            <Button
              v-tooltip="{
                value: $t('error.not_enough_permission.to_update'),
                disabled: havePermissionForUpdate,
                context: {
                  right: true,
                },
              }"
              variant="primary"
              type="submit"
              :disabled="!havePermissionForUpdate"
              class="w-24 mt-3"
              >{{ $t("save") }}
            </Button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from "#i18n";
  import { computed, toRef } from "vue";
  import type { IUser, UsersGatewayInterface } from "~/entities/user";
  import Button from "~/shared/ui/Button";
  import { iocContainer } from "~/inversify.config";
  import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
  import { toaster } from "~/service/toaster";
  import {
    FieldsFormDisplay,
    useFieldsEventListeners,
    useCollectionFieldsIncluding,
  } from "~/entities/field";
  import { useCollecitonsStore } from "~/stores/collections";
  import { usePermissionsStore } from "~/stores/permissions";
  import { PermissionAction } from "~/api/permissions/entity/PermissionInterface";

  interface Props {
    profileData: IUser;
  }

  const props = defineProps<Props>();

  const emit = defineEmits<{
    "update:user": [payload: IUser];
  }>();

  const { t } = useI18n();

  const collectionsStore = useCollecitonsStore();
  const collection = computed(() => collectionsStore.getCollection("directus_users"));

  const permissionsStore = usePermissionsStore();

  const havePermissionForUpdate = computed<boolean>(() =>
    permissionsStore.hasCollectionPermission("directus_users", PermissionAction.UPDATE),
  );

  const computedProfileData = computed(() => {
    return props.profileData;
  });

  const { fields } = useCollectionFieldsIncluding(toRef("directus_users"), [
    "role",
    "status",
  ]);

  const { fieldsEventListeners } = useFieldsEventListeners(computedProfileData);

  async function onUpdateUser(): Promise<void> {
    try {
      const userApi = iocContainer.get<UsersGatewayInterface>(
        INJECT_SYMBOLS.UsersGatewayInterface,
      );

      const updatedUser = await userApi.updateUser(
        props.profileData.id as string,
        props.profileData,
      );

      toaster().success(t("user_updated_message"));
      emit("update:user", updatedUser);
    } catch (err) {
      // @ts-expect-error
      toaster().error(`${t("error_user_update")}: ${err!.message}`);
    }
  }
</script>
